import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Description, Title } from './roadmapElementStyles';

const RoadmapElement = ({ title, description, Icon, extra }) => {
    return (
        <VerticalTimelineElement
            contentStyle={{
                background: 'var(--accent)',
                color: '#fff',
                
            }}
            contentArrowStyle={{
                borderRight: '7px solid var(--accent)',
            }}
            iconStyle={{
                background: 'var(--accent)',
                color: 'var(--primary)',
            }}
            date={extra}
            dateClassName='timeline-date-style'
            icon={<Icon />}
        >
            <Title>{title}</Title>
            <Description>{description}</Description>
        </VerticalTimelineElement>
    );
};

export default RoadmapElement;
