import { Container, Title } from './sectionHeadingStyles';

const SectionHeading = ({ title, titleColor }) => {
    return (
        <Container>
            <Title color={titleColor}>{title}</Title>
        </Container>
    );
};

export default SectionHeading;
