import { SectionHeading } from '..';
import { Container, GridWrapper } from './thumbnailsStyles';
import img1 from '../../assets/castle.png';
import img2 from '../../assets/Bara_Skrae.png';
import img3 from '../../assets/Guild.png';
import img4 from '../../assets/Orc_lair.png';
import img5 from '../../assets/Bitshrine.png';
import img6 from '../../assets/Shipyards.png';
import Thumbnail from './Thumbnail';

const Thumbnails = () => {
    const images = [
        {
            id: 1,
            src: img1,
            title: 'Castle Doje (location 1)',
            desc: 'The ancestral home of the Dojian Elves, Guardians of the Realm and its many treasures.',
        },
        {
            id: 2,
            src: img2,
            title: 'Ruins of Bara Skrae (location 4)',
            desc: 'No one quite knows what grand peoples lived here all those years ago...it\'s full of catacombs... ',
        },
        {
            id: 3,
            src: img3,
            title: 'The Guild (location 2)',
            desc: 'Treasure seekers gather here for solidarity and for the booze.',
        },
        {
            id: 4,
            src: img4,
            title: 'The Orc Lair (location 5)',
            desc: 'The Orc\'s stronghold and lands...they bumrushed out of here on the hunt for moolah.',
        },
        {
            id: 5,
            src: img5,
            title: 'Bit shrine (location 3)',
            desc: 'There be digital magic money in there somewheres, or so the legend goes.',
        },
        {
            id: 6,
            src: img6,
            title: 'Shipyards of Elon Bezos (location 6)',
            desc: 'You gotta go here to get a ship to go to different parts of the realm – the master of the shipyards is a greedy mfer.',
        },
    ];

    return (
        <Container>
            <SectionHeading title='The Locations in Dojia' titleColor='black' />

            <GridWrapper>
                {images.map(image => (
                    <Thumbnail
                        src={image.src}
                        title={image.title}
                        desc={image.desc}
                        key={image.id}
                    />
                ))}
            </GridWrapper>
        </Container>
    );
};

export default Thumbnails;
