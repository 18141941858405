import styled from "styled-components";

export const Container = styled.div`
    padding: 4rem;
    background: #000;
    color: white;

    @media (max-width: 768px) {
        padding: 2rem;
    }
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    `

export const FooterInfo = styled.div`
    flex : 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }
`
export const FooterLogo = styled.img`
    width: 150px;
    height: auto;

`
export const FooterInfoText = styled.p`
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
        font-size: 0.8rem;
        width: 80%;
    }
`
export const FooterLinks = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 4rem;

    @media (max-width: 768px) {
        justify-content: center;
        margin-right: 0;
    }
`
export const FooterLink = styled.a`
    display: flex;  
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: white;
    text-align: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #000;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
        background: #597383;
        color: #fff;
    }
`

export const CopyRightText = styled.p`
    font-size: 0.9rem;
    font-weight: 300;
    color: gray;
    margin-top: 1rem;
    text-align: center;
`