import styled from "styled-components";

export const AboutContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    /* margin-top: 20px; */
    padding-top: 20px;
    margin-bottom: 20px;
    place-items: center;
    padding: 1.5rem 0;
    background-color: #000;
    color: white;
`;

export const InfoSide = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 80%;
`;
export const InfoSideTitle = styled.h2`
    font-size: clamp(1.5rem, 3.5vw, 4rem);
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: var(--primary);

    
`;
export const InfoSideText = styled.p`
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 25px;
    line-height: 1.3;
    @media (max-width: 600px) {
        margin-bottom: 15px;
        font-size: 1rem;
    }

    span {
        display: block;
        color: #f6ce0d;
    }
`;

export const ImageSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`;

export const Button = styled.button`
    margin: 1rem auto;
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: bold;
    width: clamp(80%, 10vw, 100%);
    background: var(--primary);
    color: white;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
        transform: translateY(-5px);
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(2px);
    }

`;
export const ImageSideImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;

`;