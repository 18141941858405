import {
    ThumbnailBody,
    ThumbnailContent,
    ThumbnailImage,
} from './thumbnailsStyles';

const Thumbnail = ({ src, title, desc }) => {
    return (
        <ThumbnailBody>
            <ThumbnailImage src={src} alt={title} />

            <ThumbnailContent>
                <h3>{title}</h3>

                <p>{desc}</p>
            </ThumbnailContent>
        </ThumbnailBody>
    );
};

export default Thumbnail;
