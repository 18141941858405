import styled from 'styled-components';

export const Container = styled.section`
    padding: 0 2rem;
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fee18a;
    padding-bottom: 3rem;
    padding-top: 3rem;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 4rem;

    @media (max-width: 768px) {
        margin-right: 0;
    }
`;
export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    width: 30vw;

    @media (max-width: 768px) {
        width: 80vw;
    }

    
`;
export const LogoImg = styled.img`
    width: 100%;
`;
export const Description = styled.p`

    // font-size: 1.5rem;
    // text-align: left;
    // margin-bottom: 25px;
    // line-height: 1.3;
    // @media (max-width: 600px) {
    //     margin-bottom: 15px;
    //     font-size: 1rem;
    // }

    // span {
    //     display: block;
    //     color: #f6ce0d;
    // }
    font-size: 1.5rem;
    font-weight: bolder;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 25px;
    // font-family: 'Life Savers', sans-serif;
    max-width: 35vw;
    color: #1d0a3d;

    @media (max-width: 768px) {
        max-width: 80vw;
        font-size: 1.2rem;
    }
    span {
            display: block;
            color: #f6ce0d;
        }

`;
export const Button = styled.button`
    background-color: #3d157e;
    border: none;
    border-radius: 5px;
    color: #fff;
    border-radius: 10rem;
    font-size: 1.2rem;
    font-weight: bolder;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
    }
`;

export const Right = styled.div`
    margin-left: 4rem;

    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

export const ImageContainer = styled.div`
    width: 450px;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
`;

export const InfoSideTitle = styled.h1`
    font-size: clamp(4rem, 3.5vw, 4rem);
    font-weight: bolder;
    margin-bottom: 20px;
    text-align: center;
    color: #1d0a3d;
    // font-family: 'Life Savers', sans-serif;

`;

export const HeroAction = styled.button`
    background: ${props => props.disabled ? 'var(--secondary)' : '#ceac07'};
    color: ${props => props.disabled ? 'white' : 'white'};
    font-size: 2rem;
    padding: 1.5rem 3rem;
    margin-top: 2rem;
    border: none;
    border-radius: 10rem;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};

    span {
        font-size: 1.75rem;
        font-weight: bold;
        font-family: 'Life Savers', sans-serif;
    }

    &:hover {
        transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(2px);
    }

    @media (max-width: 1024px) {
        font-size: 1.5rem;
        padding: 1rem 2rem;

    }

    @media (max-width: 480px) {
        font-size: 1rem;
        padding: 1rem 1.5rem;
    }
`;