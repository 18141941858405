import { FaDiscord, FaTwitter } from 'react-icons/fa';
import {
    Container,
    CopyRightText,
    FooterInfo,
    FooterInfoText,
    FooterLink,
    FooterLinks,
    FooterLogo,
    Wrapper,
} from './footerStyles';
import logo from '../../assets/mmc-logo-text.png';

const Footer = () => {
    return (
        <Container>
            <Wrapper>
                <FooterInfo>
                    <FooterLogo src={logo} alt='logo' />
                    <FooterInfoText>
                        <a href="https://etherscan.io/address/0x58f3996fa2afcf56abe99131c8c1b53ba00d6a44">Smart contract link</a>
                    </FooterInfoText>
                </FooterInfo>

                <FooterLinks>
                    <FooterLink>
                        <a href= "https://discord.gg/YMKdvqb6Z4"><FaDiscord /></a>
                    </FooterLink>
                    <FooterLink>
                        <a href="https://twitter.com/MoolahClub"><FaTwitter /></a>
                    </FooterLink>
                </FooterLinks>
            </Wrapper>
            <CopyRightText>
                &copy;2022 Metaverse Moolah Club. All rights reserved.
            </CopyRightText>
        </Container>
    );
};

export default Footer;
