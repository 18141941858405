import styled from "styled-components";

export const Title = styled.h3`
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #fff;
    letter-spacing: 0.1rem;
`;

export const Description = styled.p`
    font-size: 1.25rem !important;
`  ;