import { scroller } from 'react-scroll';

import {
    Button,
    Container,
    Description,
    HeroAction,
    Image,
    ImageContainer,
    InfoSideTitle,
    Left,
    Logo,
    LogoImg,
    Right,
    Wrapper,
} from './NewHeroStyles';

import logo from '../../assets/Metaverse-logo.png';

const NewHero = () => {
    return (
        <Container>
            <Wrapper>
                <Left>
                    <InfoSideTitle>
                        Join the Quest for Moolah!
                    </InfoSideTitle>

                    <Description>
                        <span></span>
                    A collection of 5,555 Orcs are emerging from their Lair with Maps of the Realm in hand.  The Maps lead to the Treasures of the Dojian Elves and beckon you to join the quest!
                    </Description>

                    <HeroAction
                    onClick={() =>
                        scroller.scrollTo('connect', {
                            smooth: true,
                            offset: -100,
                        })
                    }
                    
                >
                    <span>Mint</span>
                    </HeroAction>
                </Left>

                <Right>
                    <ImageContainer>
                        <Image src='/config/images/preview.gif' alt='example' />
                    </ImageContainer>
                </Right>
            </Wrapper>
        </Container>
    );
};

export default NewHero;
