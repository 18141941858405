import styled from "styled-components";

export const Section = styled.section`
    padding-bottom: 100px;
    background-color: #371C79;
    color: white;
    

`;

export const Container = styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
    margin: 4rem 0;
    position: relative;

    @media (max-width: 1150px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`

export const CarouselH2 = styled.h2`
    color: var(--primary);
    /* margin-bottom: 75px; */
    font-size: 72px;
    font-weight: 900;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 48px;
    }
`
export const CarouselCard = styled.div`
    padding: 0 5px;

    &:focus-visible {
        outline: none;
    }
`

export const CarouselImg = styled.img`
    height: 320px;
    object-fit: cover;
    border-radius: 9px;
    width: 100%;

    &:focus-visible {
        outline: none;
    }

`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;


export const NextButton = styled.button`
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #febf03;
    color: #000;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    margin-left: 2rem;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 34px;
        height: 34px;
    }
`

export const PrevButton = styled.button`
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #febf03;
    color: #000;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 34px;
        height: 34px;
    }
`