import { RoadmapElement, SectionHeading } from '..';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { roadmapData } from './roadmapData';
import { RoadmapContainer, RoadmapDescription, RoadmapConclusion } from './roadmapStyles';
const Roadmap = () => {
    return (
        <>
            <SectionHeading title='Roadmap' titleColor='black' />

            <RoadmapDescription>
            The adventure begins!  Check out the roadmap filled with exciting features and events for our community including giveaways, puzzle contests, free merch, special nft drops, utility, planned comics and play-to-earn gaming!
            </RoadmapDescription>

            <RoadmapContainer id='roadmap'>
                <VerticalTimeline>
                    {roadmapData.map(roadmap => (
                        <RoadmapElement
                            key={roadmap.id}
                            title={roadmap.title}
                            Icon={roadmap.Icon}
                            extra={roadmap.extra}
                            description={roadmap.description}
                        />
                    ))}
                </VerticalTimeline>

                <RoadmapConclusion>
                Roadmap V2
                </RoadmapConclusion>

            </RoadmapContainer>
        </>
    );
};

export default Roadmap;
