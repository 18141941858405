import team1 from '../../assets/Megamind.png';
import team2 from '../../assets/RAMbrandt.png';
import team3 from '../../assets/Neo.png';

export const teamData = [
    {
        id: 1,
        name: 'Megamind',
        position: 'The mastermind behind the moolah',
        photo: team1,
        discord: 'https://discord.gg/YzjyYj',
        twitter: 'https://twitter.com/Alex_Konstantinov',
    },
    {
        id: 2,
        name: 'RAMbrandt',
        position: 'Artist and macaroni and cheese lover',
        photo: team2,
        discord: 'https://discord.gg/YzjyYj',
        twitter: 'https://twitter.com/Alex_Konstantinov',
    },
    {
        id: 3,
        name: 'Neo',
        position: 'Coder who drinks wine while coding',
        photo: team3,
        discord: 'https://discord.gg/YzjyYj',
        twitter: 'https://twitter.com/Alex_Konstantinov',
    },
]