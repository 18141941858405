import styled from "styled-components";

export const TeamContainer = styled.section`
    padding: 4rem;
    width: 100%;
    background: #000;
    color: #fff;
    border-bottom: 1px solid #597383;
    @media (max-width: 768px) {
        width: 100%;
        padding: 3rem;
    }

    @media (max-width: 480px) {
        padding: 0;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TeamDescription = styled.p`
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
    max-width: 60%;
    line-height: 1.3;

    @media (max-width: 768px) {
        max-width: 80%;
    }

    @media (max-width: 480px) {
        font-size: 1rem;
        max-width: 100%;

    }
`

export const TeamCardContainer = styled.div`
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 2rem;
    place-items: center;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
`;


export const TeamCard = styled.div`
    width: 100%;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
`
export const TeamCardDetails = styled.div`
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    padding: 2rem;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);

    h4 {
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 1rem;
        color: #fff;
    }

    p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 1rem;
        color: #fff;
    }
`

export const TeamImageContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
`

export const TeamImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
`

export const TeamSocialContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

`
export const Icon = styled.div`
    margin: 0 0.5rem;
    
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: translateY(-3px);
    }

    a {
        color: #597383;
    }
`