import styled from "styled-components";

export const RoadmapContainer = styled.section`
    width: 100%;
    margin-bottom: 4rem;
    
`

export const RoadmapDescription = styled.p`
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
    margin: .75rem auto;
`

export const RoadmapConclusion = styled.p`
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
    margin: .75rem auto;
`;