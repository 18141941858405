import React from 'react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { SectionHeading } from '..';
import { teamData } from './teamData';
import {
    Icon,
    TeamCard,
    TeamCardContainer,
    TeamCardDetails,
    TeamContainer,
    TeamDescription,
    TeamImage,
    TeamImageContainer,
    TeamSocialContainer,
    Wrapper,
} from './teamStyles';

const Team = () => {
    return (
        <TeamContainer id='team'>
            <Wrapper>
                <SectionHeading title='Our Team' titleColor='var(--primary)' />

                {/* <TeamDescription>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minus ex, natus neque maiores labore quas magni ad
                    reiciendis officia rem hic deleniti exercitationem
                    doloremque atque rerum debitis excepturi eveniet id quae
                    tempora aut saepe. Ea ad quisquam itaque eius perspiciatis
                    doloribus fugit labore nobis aliquid expedita harum, rerum
                    quas fugiat?
                </TeamDescription> */}

                <TeamCardContainer>
                    {teamData.map(member => (
                        <TeamCard key={member.id}>
                            {/* <TeamCardDetails>
                                <h4>{member.name}</h4>
                                <p>{member.position}</p>
                            </TeamCardDetails> */}
                            <TeamImageContainer>
                                <TeamImage
                                    src={member.photo}
                                    alt={member.name}
                                />
                            </TeamImageContainer>

                            <TeamCardDetails>
                                <h4>{member.name}</h4>
                                <p>{member.position}</p>
                            </TeamCardDetails>

                            {/* <TeamSocialContainer>
                                {member.discord && (
                                    <Icon>
                                        <a
                                            href={member.discord}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FaDiscord />
                                        </a>
                                    </Icon>
                                )}
                                {member.twitter && (
                                    <Icon>
                                        <a
                                            href={member.twitter}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FaTwitter />
                                        </a>
                                    </Icon>
                                )}
                            </TeamSocialContainer> */}
                        </TeamCard>
                    ))}
                </TeamCardContainer>
            </Wrapper>
        </TeamContainer>
    );
};

export default Team;
