import styled from "styled-components";


export const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* width: 100vw; */
    position: relative;
    z-index: 2;
    overflow-x: hidden;

    background: ${props => props.bg ? `url(${props.bg})` : "transparent"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    // make disabled styles

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        z-index: -1;

    }
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const HeroTitle = styled.h1`
    font-size: 5rem;
    color: white;
    max-width: 60%;
    text-align: center;
    margin: 2rem 0;
    color: var(--primary);

    @media (max-width: 1024px) {
        font-size: 5rem;
    }

    @media (max-width: 768px) {
        font-size: 4rem;
    }

    @media (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const HeroAction = styled.button`
    background: ${props => props.disabled ? 'var(--secondary)' : '#ceac07'};
    color: ${props => props.disabled ? 'white' : 'white'};
    font-size: 2rem;
    padding: 1.5rem 3rem;
    margin-top: 2rem;
    border: none;
    border-radius: 10rem;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};

    span {
        font-size: 1.5rem;
    }

    &:hover {
        transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(2px);
    }

    @media (max-width: 1024px) {
        font-size: 1.5rem;
        padding: 1rem 2rem;

    }

    @media (max-width: 480px) {
        font-size: 1rem;
        padding: 1rem 1.5rem;
    }
`;