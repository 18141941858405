import {
    Button,
    ButtonsContainer,
    HamburgerButton,
    LinkWithIcon,
    Logo,
    LogoContainer,
    Nav,
    NavItem,
    NavLink,
    NavMenu,
    Wrapper,
} from './navbarStyles';

import logo from '../../assets/mmc-logo-text.png';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-scroll';
import MetaMaskIcon from '../MetaMaskIcon';
import { useState } from 'react';

const Navbar = () => {
    const [open, setOpen] = useState(true);

    return (
        <Nav open={open}>
            <Wrapper open={open}>
                <LogoContainer>
                    <Logo src={logo} alt='logo' />
                </LogoContainer>

                <NavMenu open={open}>
                    <NavItem>
                        <NavLink>
                            <Link to='roadmap' smooth offset={-100}>
                                Roadmap
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink>
                            <Link to='team' smooth>
                                Team
                            </Link>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        {/* <NavLink>
                            <a
                                href='/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Opensea
                            </a>
                        </NavLink> */}
                    </NavItem>
                </NavMenu>

                <ButtonsContainer open={open}>
                    <Button>
                        <a href="https://discord.gg/YMKdvqb6Z4"><FaDiscord /></a>
                    </Button>
                    <Button>
                        <a href="https://twitter.com/MoolahClub"><FaTwitter /></a>
                    </Button>

                    {/* <LinkWithIcon>
                        Join Discord
                        <span>
                            <FaDiscord />
                        </span>
                    </LinkWithIcon> */}
                    {/* Connnect Button */}
                    {/* <LinkWithIcon> */}
                    {/* <Link to='connect' smooth> */}
                    {/* Connect */}
                    {/* </Link> */}
                    {/* Keep the icon inside the span */}
                    {/* <span> */}
                    {/* Change the icon as your need */}
                    {/* <MetaMaskIcon /> */}
                    {/* </span> */}
                    {/* </LinkWithIcon> */}
                </ButtonsContainer>
            </Wrapper>

            <HamburgerButton open={open} onClick={() => setOpen(prev => !prev)}>
                <span></span>
                <span></span>
                <span></span>
            </HamburgerButton>
        </Nav>
    );
};

export default Navbar;
