import imgOne from '../../assets/28.png';
import imgTwo from '../../assets/2649.png';
import imgThree from '../../assets/2515.png';
import imgFour from '../../assets/124.png';
import imgFive from '../../assets/69.png';
import imgSix from '../../assets/78.png';
import imgSeven from '../../assets/210.png';


import Slider from 'react-slick';
import {
    ButtonsContainer,
    CarouselCard,
    CarouselH2,
    CarouselImg,
    Container,
    NextButton,
    PrevButton,
    Section,
    Wrapper,
} from './carouselStyles';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

const CustomNextArrow = props => {
    const { onClick, className, style } = props;

    return (
        <NextButton
            className={className}
            style={{
                ...style,
                top: '-105px',
                right: '20px',
                left: 'inherit',
                position: 'absolute',
                display: 'grid',
                placeItems: 'center',
            }}
            onClick={onClick}
        >
            <FiChevronRight />
        </NextButton>
    );
};

const CustomPrevArrow = props => {
    const { onClick, className, style } = props;

    return (
        <PrevButton
            className={className}
            style={{
                ...style,
                top: '-105px',
                right: '80px',
                left: 'inherit',
                position: 'absolute',
                display: 'grid',
                placeItems: 'center',
                color: 'primary',
            }}
            onClick={onClick}
        >
            <FiChevronLeft />
        </PrevButton>
    );
};

const Carousel = () => {
    const imgs = [
        imgOne,
        imgTwo,
        imgThree,
        imgFour,
        imgFive,
        imgSix,
        imgSeven,
       
    ];
    const settings = {
        dots: false,
        // arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: 'linear',
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            { breakpoint: 1400, settings: { slidesToShow: 4, }, },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <Section>
            <Wrapper>
                <CarouselH2>The Orcs with Maps</CarouselH2>
            </Wrapper>
            <Container>
                <Slider {...settings} arrows>
                    {imgs.map((el, ind) => {
                        return (
                            <CarouselCard key={el + '_' + ind}>
                                <CarouselImg src={el} alt={`ape - ${ind}`} />
                            </CarouselCard>
                        );
                    })}
                </Slider>
            </Container>
        </Section>
    );
};

export default Carousel;
