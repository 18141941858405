import { GiCarnivorousPlant, GiNuclearPlant, GiPlantSeed, GiPlantWatering, GiSeedling } from "react-icons/gi"

export const roadmapData = [
    {
        id: 1,
        title: 'The Orcs are coming! ',
        description: 'Our pre-launch is filled with exciting features and events for our community including giveaways, events and contests!  Be sure and join our Discord! to stay in the loop!',
        Icon: GiPlantSeed,
        extra: 'Pre-launch',
    },
    {
        id: 2,
        title: 'Big Reveals',
        description: 'Time to reveal the 1/1 in the collection.  We may also reveal some of the cool treasures you can find with the right map...',
        Icon: GiSeedling,
        extra: '10%',
    },
    {
        id: 3,
        title: 'Givaways!',
        description: 'Giveaway of 10 NFTs from the collection to early supporters.  That\'s not all. 1ETH prize to one lucky OG NFT owner',
        Icon: GiCarnivorousPlant,
        extra: '25%',
    },
    {
        id: 4,
        title: 'Charity donation and rare treasure reveal',
        description: 'We will give a $10,000 donation to a charity selected by our community. The rarest treasure in the Realm unveiled along with the big reveal of the hodler of the NFT with the map that leads to it!',
        Icon: GiPlantWatering,
        extra: '50%',
    },
    {
        id: 5,
        title: 'The Return of the Elves',
        description: 'We will launch the Dojian Elves collection – the orcs aren’t the only ones in the Realm!  The elves won’t give up the treasures of the Realm without a fight…exclusive pre-sale access to the Elves will be given to hodlers of the Orc NFTs.  And, 5ETH will be given to 5 lucky Orc NFT hodlers.',
        Icon: GiNuclearPlant,
        extra: '100%',
    }
]