import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import styled from 'styled-components';
import { About, Carousel, Footer, Hero, NewHero, Map, Navbar, Roadmap, Team, Thumbnails } from './components';
import { FaMinus, FaPlus } from 'react-icons/fa';

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
    border-radius: 50px;
    border: none;
    background-color: var(--secondary);
    padding: 15px 35px;
    font-weight: bold;
    color: var(--secondary-text);
    cursor: pointer;
    box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`;

export const StyledRoundButton = styled.button`
    padding: 10px;
    border-radius: 100%;
    border: none;
    background-color: var(--primary);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    color: var(--primary-text);
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`;

export const ResponsiveWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretched;
    align-items: stretched;
    width: ${props => (props.width ? props.width : '100%')};
    overflow-x: hidden;

    @media (min-width: 767px) {
        width: 70%;
        flex-direction: row;
        padding: 0px 10px;
    }

    @media (min-width: 1024px) {
        width: 50%;
    }

    @media (max-width: 767px) {
        width: 80%;
    }

    @media (max-width: 575px) {
        width: 100%;
    }
`;

export const StyledLogo = styled.img`
    width: 200px;
    @media (min-width: 767px) {
        width: 300px;
    }
    transition: width 0.5s;
    transition: height 0.5s;
`;

export const StyledTitle = styled.h1`
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-text);
    line-height: 1.2;
    text-align: center;
    margin: 0;
    @media (min-width: 767px) {
        font-size: 3rem;
    }

    span {
        font-size: 3.2rem;
        font-weight: bold;
        color: var(--primary-text);
        line-height: 1.2;
        text-align: center;
        margin: 0;
        @media (min-width: 767px) {
            font-size: 4rem;
        }
    }
`;

export const AmmountContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid white;
`;

export const AmmountControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    color: 'white';

    div {
        color: white;
        user-select: none;
    }

    svg {
        font-size: 1.5rem;
        cursor: pointer;
        color: 'white' !important;
    }
`;

export const StyledImg = styled.img`
    box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
    border: 4px dashed var(--secondary);
    background-color: var(--accent);
    border-radius: 100%;
    width: 200px;
    @media (min-width: 900px) {
        width: 250px;
    }
    @media (min-width: 1000px) {
        width: 300px;
    }
    transition: width 0.5s;
`;

export const StyledLink = styled.a`
    color: var(--secondary);
    text-decoration: none;
`;

function App() {
    const dispatch = useDispatch();
    const blockchain = useSelector(state => state.blockchain);
    const data = useSelector(state => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click 'Buy' to mint your Metaverse Moolah Club NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: '',
        SCAN_LINK: '',
        NETWORK: {
            NAME: '',
            SYMBOL: '',
            ID: 0,
        },
        NFT_NAME: '',
        SYMBOL: '',
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: '',
        MARKETPLACE_LINK: '',
        SHOW_BACKGROUND: false,
    });

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log('Cost: ', totalCostWei);
        console.log('Gas limit: ', totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once('error', err => {
                console.log(err);
                setFeedback(
                    'Sorry, something went wrong please try again later.'
                );
                setClaimingNft(false);
            })
            .then(receipt => {
                console.log(receipt);
                setFeedback(
                    `Success!  The ${CONFIG.NFT_NAME} moolah is yours!`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 5) {
            newMintAmount = 5;
        }
        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (blockchain.account !== '' && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    return (
        <s.Screen>
            <Navbar />
            
            <NewHero />
            <Carousel/>
            <Map />
            <Thumbnails />
            <Roadmap />
            
  {/* Uncomment/Comment this Container reveal/hide the Minting Section on line 276*/}
        <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)' }}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/scroll.jpg' : null}
        >
        {/* <StyledLogo
            alt={'logo'}
            src={'/config/images/logo.png'}
            id='connect'
        /> */}
        <StyledTitle id='connect'>
            Get your orc and map!
            <br />
            <span>
                {data.totalSupply}/{CONFIG.MAX_SUPPLY}
            </span>
        </StyledTitle>
        <s.SpacerSmall />
        <ResponsiveWrapper width='60%' test>
            {/* <s.Container flex={1} jc={'center'} ai={'center'}>
                <StyledImg
                    alt={'example'}
                    src={'/config/images/example.gif'}
                />
            </s.Container> */}
            <s.SpacerLarge />
            <s.Container
                flex={2}
                jc={'center'}
                padding='3.5rem 5rem'
                ai={'center'}
                style={{
                    backgroundColor: 'rgba(0,0,0,0.65)',

                    borderRadius: 24,
                    // border: '4px dashed var(--secondary)',
                    boxShadow: '0px 5px 11px 2px rgba(0,0,0,0.7)',
                    overflowX: 'hidden',
                }}
            >
                {/* <s.TextTitle
                    style={{
                        color: 'white',

                        borderBottom: '2px solid white',
                        width: '100%',
                        display: 'block',
                        padding: '0.5rem 0',
                        textTransform: 'uppercase',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    Contract&nbsp;&nbsp;&nbsp;
                    <StyledLink
                        target={'_blank'}
                        href={CONFIG.SCAN_LINK}
                        style={{
                            textTransform: 'initial',
                            // if the text overflows, it will be truncated
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                    </StyledLink>
                </s.TextTitle> */}
                

              

                <s.SpacerSmall />
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                        <s.TextTitle
                            style={{
                                textAlign: 'center',
                                color: 'var(--accent-text)',
                            }}
                        >
                            The sale has ended.
                        </s.TextTitle>
                        <s.TextDescription
                            style={{
                                textAlign: 'center',
                                color: 'var(--accent-text)',
                            }}
                        >
                            You can still find {CONFIG.NFT_NAME} on
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <StyledLink
                            target={'_blank'}
                            href={CONFIG.MARKETPLACE_LINK}
                        >
                            {CONFIG.MARKETPLACE}
                        </StyledLink>
                    </>
                ) : (
                    <>
                        <s.TextTitle
                            style={{
                                textAlign: 'center',
                                color: 'var(--accent-text)',
                            }}
                        >
                            1 {CONFIG.SYMBOL} costs{' '}
                            {CONFIG.DISPLAY_COST}{' '}
                            {CONFIG.NETWORK.SYMBOL}.
                        </s.TextTitle>
                        <s.SpacerXSmall />
                        
                        <s.SpacerSmall />
                        {blockchain.account === '' ||
                        blockchain.smartContract === null ? (
                            <s.Container ai={'center'} jc={'center'}>
                                <s.TextDescription
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    Please connect your metamask to mint. Click on the metamask chrome extension if you don't see an automatic popup.
                                </s.TextDescription>
                                <s.SpacerSmall />
                                <StyledButton
                                    onClick={e => {
                                        e.preventDefault();
                                        dispatch(connect());
                                        getData();
                                    }}
                                >
                                    CONNECT
                                </StyledButton>
                                {blockchain.errorMsg !== '' ? (
                                    <>
                                        <s.SpacerSmall />
                                        <s.TextDescription
                                            style={{
                                                textAlign: 'center',
                                                color: 'var(--accent-text)',
                                            }}
                                        >
                                            {blockchain.errorMsg}
                                        </s.TextDescription>
                                    </>
                                ) : null}
                            </s.Container>
                        ) : (
                            <>
                                <s.TextDescription
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    {feedback}
                                </s.TextDescription>
                                <s.SpacerMedium />
                                <s.Container
                                    ai={'center'}
                                    jc={'center'}
                                    fd={'row'}
                                >
                                    <StyledRoundButton
                                        style={{ lineHeight: 0.4 }}
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={e => {
                                            e.preventDefault();
                                            decrementMintAmount();
                                        }}
                                    >
                                        -
                                    </StyledRoundButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                        style={{
                                            textAlign: 'center',
                                            color: 'var(--accent-text)',
                                        }}
                                    >
                                        {mintAmount}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledRoundButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={e => {
                                            e.preventDefault();
                                            incrementMintAmount();
                                        }}
                                    >
                                        +
                                    </StyledRoundButton>
                                </s.Container>
                                <s.SpacerSmall />
                                <s.Container
                                    ai={'center'}
                                    jc={'center'}
                                    fd={'row'}
                                >
                                    <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={e => {
                                            e.preventDefault();
                                            claimNFTs();
                                            getData();
                                        }}
                                    >
                                        {claimingNft ? 'BUSY' : 'BUY'}
                                    </StyledButton>
                                </s.Container>
                            </>
                        )}
                    </>
                )}
                <s.SpacerMedium />
            </s.Container>
            <s.SpacerLarge />
            {/* <s.Container flex={1} jc={'center'} ai={'center'}>
                <StyledImg
                    alt={'example'}
                    src={'/config/images/example.gif'}
                    style={{ transform: 'scaleX(-1)' }}
                />
            </s.Container> */}
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container
            jc={'center'}
            ai={'center'}
            style={{ width: '70%' }}
        >
            {/* <s.TextDescription
                style={{
                    textAlign: 'center',
                    color: 'var(--primary-text)',
                }}
            >
                Please make sure you are connected to the right network
                ({CONFIG.NETWORK.NAME} Mainnet) and the correct address.
                Please note: Once you make the purchase, you cannot undo
                this action.
            </s.TextDescription>
            <s.SpacerSmall />
            <s.TextDescription
                style={{
                    textAlign: 'center',
                    color: 'var(--primary-text)',
                }}
            >
                We have set the gas limit to {CONFIG.GAS_LIMIT} for the
                contract to successfully mint your NFT. We recommend
                that you don't lower the gas limit.
            </s.TextDescription> */}
        </s.Container>
        </s.Container>          
            <Team />
            <Footer />
        </s.Screen>
    );
}

export default App;
