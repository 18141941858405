import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    padding: 2rem 4rem;
    padding-bottom: 4rem;
    color: #000;
    
`

export const GridWrapper = styled.div`
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 4rem;
    margin-top: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
`;


export const ThumbnailBody = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ThumbnailImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 5px;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }
`;


export const ThumbnailContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 0.5rem;

    h3 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        color: #000;
    }

    @media (max-width: 768px) {
        margin-left: 0;
        text-align: center;
    }
`;