import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;    
    align-items: center;

    width: 100%;
    padding: 2rem 0;
    background: ${props => props.bg};

`;

export const Title = styled.h1`
    font-size: 4rem;
    font-weight: bold;
    color: ${props => props.color || 'var(--secondary)'};
    text-align: center;
    /* text-shadow: 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 0px 0px 3px #EBC908, 4px 4px 4px #000000; */
`