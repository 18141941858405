import { SectionHeading } from '../..';
import {
    AboutContainer,
    ImageSide,
    ImageSideImage,
    InfoSide,
    InfoSideText,
    InfoSideTitle,
    Button,
} from './mapStyles';

const Map = () => {
    return (
        <>
            {/* <SectionHeading title='About Us' /> */}
            <AboutContainer>

            <ImageSide className='image'>
                    <ImageSideImage
                        src='/config/images/map1.png'
                        alt='example'
                    />
                    <Button>
                        <span>Minting is live!</span>
                    </Button>
                    {/* <InfoSideText>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </InfoSideText> */}
                </ImageSide>

                <InfoSide className='info'>
                    <InfoSideTitle>
                        The Map Leads to the Moolah!
                    </InfoSideTitle>

                    <InfoSideText>
                        <span></span>
                        {/* A collection of 5,555 Orcs are emerging from the Lair with Maps of the Realm in hand.  The Maps lead to the Treasures of the Dojian Elves and beckon you to join the quest! */}
                    </InfoSideText>
                    <InfoSideText>
                    Metaverse Moolah Club is not just aping into the trend with artwork of the next furry zoo animal…
                    <br></br>
                    <br></br>
                    We have combined a cool looking orc character with a fantasy-style/RPG map right into the artwork for the project.  
                    <br></br>
                    <br></br>
                    The map is right on the NFT and has 6 possible locations...some maps have 1 location identified – a few rare ones have all 6. Depending on which map you get, you'll gain access to unique NFT treasures and prizes!
  
                    </InfoSideText>
                    <InfoSideText>
                        Minting is live!  Stay tuned for post-launch events and giveaways!
                    </InfoSideText>

                    {/* <InfoSideText>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Obcaecati, non aspernatur quidem beatae ipsam culpa!
                        Explicabo laudantium natus nesciunt veritatis
                        necessitatibus velit, assumenda perspiciatis magnam!
                    </InfoSideText> */}
                </InfoSide>


            </AboutContainer>
        </>
    );
};

export default Map;
