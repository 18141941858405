import styled from "styled-components";

export const Nav = styled.nav`
    /* overflow: hidden; */
    height: 80px;
    background-color: #000;
    color: 'white';
    position: relative;

    transition: all 0.5s ease-in-out;

    @media (max-width: 825px) {
        height: ${props => (props.open ? "auto" : "80px")};
    }
`;
export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: visible;

    transition: all 0.5s ease-in-out;

    @media (max-width: 825px) {
        flex-direction: column;
    }

`;
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 80px;
    margin-right: auto;
    margin-left: 1rem;
    padding: 1rem;

    @media (max-width: 825px) {
        margin-left: 0;
        margin-right: 0;
    }
 

    /* @media (max-width: 768px) {
        width: 150px;
        height: 50px;
    }

    @media (max-width: 425px) {
        display: none;
    } */
`;
export const Logo = styled.img`
    width: 100%;
    /* height: 100%; */

`;
export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    transition: all 0.5s ease-in-out;

    @media (max-width: 825px) {
        margin-left: 0;
        margin-right: 0;
        transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-1500%)'};

    }

    @media (max-width: 768px) {
        flex: 2;
    }

    @media (max-width: 425px) {
        flex-direction: column;
    }
`;
export const NavItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;

    @media (max-width: 768px) {
        padding: 5px;
    }

    
`;
export const NavLink = styled.span`
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    a {
        color: #fff;
        text-decoration: none;
    }

`;

export const LinkWithIcon = styled.div` 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #ceac07;
    transition: all 0.3s ease-in-out;
    margin-left: 20px;
    /* give every child some space around */
    & > * {
        margin: 0 5px;
    }

    // give the svg a rounded white background
    span {
        background-color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
            
        }
    }

    &:hover {
        background-color: #ac8f06;
    }

`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 20px;
    transition: all 0.5s ease-in-out;
    @media (max-width: 825px) {
        transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(-1500%)'};
    }
`;
export const Button = styled.button`
    /* Circular icon button styles */

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: var(--primary);
    color: #fff;
    width: 40px;
    height: 40px;
    border: none;
    margin: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #fff;
        color: #000;
    }

`;


export const HamburgerButton = styled.div`
    /* A hamburger icon made with divs */

    display: none;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: #597383;
    color: #fff;
    width: 40px;
    height: 40px;
    border: none;
    margin: 5px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    span {
        display: block;
        width: 25px;
        height: 2px;
        background-color: #fff;
        margin: 3px ;
        transition: all 0.5s ease-in-out;


        &:nth-child(1) {
            transform: ${({ open }) => open ? 'rotate(-45deg) translate(-2px, -2px)' : 'rotate(0)'};
            transform-origin: right bottom;    
        }

        &:nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        &:nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(45deg) translate(0px, 3px)' : 'rotate(0)'};
            transform-origin: right top;
        }
    }

    &:hover {
        background-color: #fff;
        color: #000;

        span {
            background-color: #000;
        }
    }



    @media (max-width: 825px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0.5rem;
    }

`